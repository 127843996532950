<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <div v-show="!fileView.state">
              <b-overlay :show="fileView.loading" rounded="sm">
                <b-row>
                  <b-col>
                    <b-form-select
                      v-model="faculty_selected"
                      :options="faculties"
                      @change="getFacultyLibraryBookCategories"
                    ></b-form-select>
                  </b-col>
                  <b-col>
                    <b-form-select
                      v-model="category_selected"
                      :options="categories"
                      @change="getLibraryBooksList"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row>
                  <b-table
                    striped
                    hovelo
                    show-empty
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    class="mt-3"
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("LIBRARY.NO_LIBRARY_BOOKS") }}
                      </p>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                      <b-button
                        pill
                        size="sm"
                        variant="info"
                        v-b-tooltip.hover
                        :title="$t('LIBRARY.BOOK_SHOW')"
                        @click="showBookDetail(item)"
                      >
                        <i
                          class="flaticon2-information"
                          style="font-size: 1.3em; margin-left: 0;"
                        ></i>
                      </b-button>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-col>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="total_rows"
                      :per-page="items_per_page"
                      align="center"
                      @change="getLibraryBooksList"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-overlay>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <template>
      <div>
        <b-modal
          no-close-on-backdrop
          ok-only
          ok-variant="secondary"
          ok-title="بستن"
          ref="showBookDetailModal"
          centered
          :title="$t('LIBRARY.BOOK_DETAIL')"
          size="lg"
        >
          <template v-slot:modal-header-close><i></i></template>
          <b-overlay variant="transparent" rounded="sm">
            <div>
              <b-card
                border-variant="dark"
                header-bg-variant="dark"
                header-text-variant="white"
                :header="$t('GENERAL.DETAILS')"
                align="left"
                style="margin-top: 20px;"
              >
                <b-card-text>
                  <template>
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.TITLE") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.title }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.PUBLISHER") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.publisher }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.TRANSLATOR") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.translator }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.AUTHOR") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.author }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.PUBLISH_YEAR") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.publish_year }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.SHELF_NUMBER") }}: </label>
                          </b-col>
                          <b-col class="border-right">
                            <span>{{ selected_item.shelf_number }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.ISBN") }}: </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.isbn }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.EDITION") }}: </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.edition }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label
                              >{{ $t("GENERAL.NUMBER_OF_COPIES") }}:
                            </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.number_of_copies }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label
                              >{{ $t("GENERAL.NUMBER_OF_VOLUMES") }}:
                            </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.number_of_volumes }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.LANGUAGE") }}: </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.language }}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <label>{{ $t("GENERAL.SHELF_ROW") }}: </label>
                          </b-col>
                          <b-col>
                            <span>{{ selected_item.shelf_row }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <div>
                        <label>{{ $t("GENERAL.REMARK") }}: </label>
                        <span style="margin-right: 10px;">{{
                          selected_item.remarks
                        }}</span>
                      </div>
                    </b-row>
                  </template>
                </b-card-text>
              </b-card>
            </div>
          </b-overlay>
          <template v-slot:modal-cancel>{{ $t("GENERAL.CANCEL") }}</template>
        </b-modal>
      </div>
    </template>
  </div>
</template>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      title: this.$t("MENU.LIBRARY_BOOKS"),
      isBusy: true,
      fileView: {
        loading: false,
        state: false
      },
      fields: [
        { title: this.$t("GENERAL.SUBJECT_BOOK") },
        { author: this.$t("GENERAL.AUTHOR") },
        { shelf_number: this.$t("GENERAL.SHELF_NUMBER") },
        { shelf_row: this.$t("GENERAL.SHELF_ROW") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      items: [],
      library_type_selected: 0,
      faculties: [],
      faculty_selected: 0,
      categories: [],
      category_selected: 0,
      current_page: 1,
      total_rows: 1,
      items_per_page: 1,
      selected_item: {
        language: null,
        title: null,
        author: null,
        number_of_volumes: null,
        edition: null,
        publisher: null,
        publish_year: null,
        isbn: null,
        shelf_number: null,
        shelf_row: null,
        translator: null,
        number_of_copies: null,
        remarks: null,
        faculty_name: null,
        category_name: null
      }
    };
  },
  components: {
    KTPortlet
  },
  methods: {
    getFacultyLibraryBookCategories() {
      this.isBusy = true;
      ApiService.get(
        "library/faculty-library-book-categories/" + this.faculty_selected
      ).then(({ data }) => {
        this.categories = data;
        this.categories.unshift({
          value: 0,
          text: this.$t("GENERAL.ALL_CATEGORIES")
        });
        this.isBusy = false;
        this.getLibraryBooksList();
      });
    },
    getLibraryBooksList() {
      this.isBusy = true;
      let filters = {
        faculty_id: this.faculty_selected,
        category_id: this.category_selected
      };
      ApiService.get(
        "library/books-list?page=" + this.current_page,
        filters
      ).then(({ data }) => {
        this.items = data.items;
        this.items_per_page = data.items_per_page;
        this.total_rows = data.total_rows;
        this.isBusy = false;
      });
    },
    showBookDetail(record) {
      this.selected_item.language = record.language;
      this.selected_item.title = record.title;
      this.selected_item.author = record.author;
      this.selected_item.number_of_volumes = record.number_of_volumes;
      this.selected_item.edition = record.edition;
      this.selected_item.publisher = record.publisher;
      this.selected_item.publish_year = record.publish_year;
      this.selected_item.isbn = record.isbn;
      this.selected_item.translator = record.translator;
      this.selected_item.shelf_number = record.shelf_number;
      this.selected_item.shelf_row = record.shelf_row;
      this.selected_item.number_of_copies = record.number_of_copies;
      this.selected_item.remarks = record.remarks;
      this.selected_item.faculty_name = record.faculty_name;
      this.selected_item.category_name = record.category_name;
      this.$refs["showBookDetailModal"].show();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.LIBRARY_BOOKS"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("/library/library-faculties").then(({ data }) => {
      this.faculties = data.faculties;
      if (data.faculties[0] !== undefined)
        this.faculties.unshift({
          value: 0,
          text: this.$t("GENERAL.ALL_FACULTIES")
        });
      this.categories = data.categories;
      if (data.categories[0] !== undefined)
        this.categories.unshift({
          value: 0,
          text: this.$t("GENERAL.ALL_CATEGORIES")
        });
      this.getLibraryBooksList();
    });
  },
  watch: {
    faculty_selected: function() {
      this.current_page = 1;
    },
    category_selected: function() {
      this.current_page = 1;
    }
  }
};
</script>
